<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="hero_content" v-if="pageIsLoaded">
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ content.subhero.surtitre }}
                </h2>
                <h1 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.subhero.titre"></span>
                </h1>
            </div>
            <div class="img" data-inview="revealRight" data-delay="200">
                <img :src="content.subhero.image.url" :alt="content.subhero.image.titre" />
            </div>
            <a href="#next" v-scroll-to:-120
                ><svg
                    fill="none"
                    class="arrow"
                    viewBox="0 0 13 26"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m11.6412 17.9322-4.17083 4.2832v-22.2154h-1.94081v22.2154l-4.17082-4.2832-1.35874 1.395 6.5 6.6728 6.5-6.6728z"
                    /></svg
            ></a>
        </section>

        <section class="txt_cell" id="next" v-if="pageIsLoaded">
            <div
                class="cell"
                data-inview="fadeInUp"
                :data-delay="2 * i + '00'"
                v-for="(item, i) in content.listeInformations1"
                :key="i"
            >
                <h2 class="title medium">{{ item.titre }}</h2>
                <p class="regular-text">
                    <span v-html="item.texte"></span>
                </p>
            </div>
        </section>

        <section class="txt_row small-padding-bottom" id="next" v-if="pageIsLoaded">
            <div class="row">
                <h4 class="title medium">{{ $t('Nos valeurs') }}</h4>

                <div class="wrap">
                    <div
                        data-inview="fadeInUp"
                        data-delay="200"
                        v-for="(item, i) in content.listeInformations2"
                        :key="i"
                    >
                        <div class="special_title">
                            <div class="number">0{{ i }}</div>
                            <h3 class="title">{{ item.titre }}</h3>
                        </div>
                        <p class="regular-text spacing-left">
                            <span v-html="item.texte"></span>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="img_txt" id="next" v-if="pageIsLoaded">
            <!-- <div class="wrap"> -->
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ content.split1.surtitre }}
                </h2>
                <h3 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.split1.titre"></span>
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.split1.texte"></span>
                </p>
                <a
                    :href="content.split1.boutonUrl[$route.meta.lang]"
                    class="cta"
                    data-inview="fadeInUp"
                    data-delay="500"
                >
                    <p class="regular-text">{{ content.split1.bouton.customText }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                </a>
            </div>
            <!-- </div> -->
            <div class="img" data-inview="revealRight" data-delay="200">
                <img :src="content.split1.image.url" :alt="content.split1.image.titre" />
            </div>
        </section>

        <section class="img_txt dark_switch" v-if="pageIsLoaded">
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ content.split2.surtitre }}
                </h2>
                <h3 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.split2.titre"></span>
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.split2.texte"></span>
                </p>
                <a
                    :href="content.split2.boutonUrl[$route.meta.lang]"
                    class="cta"
                    data-inview="fadeInUp"
                    data-delay="500"
                >
                    <p class="regular-text">{{ content.split2.bouton.customText }}</p>
                    <img src="@/assets/img/arrow-right-dark.svg" alt="arrow right" />
                </a>
            </div>
            <!-- </div> -->
            <div class="img" data-inview="revealLeftdark" data-delay="200">
                <img :src="content.split2.image.url" :alt="content.split2.image.titre" />
            </div>
        </section>

        <section class="img_txt" v-if="pageIsLoaded">
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ content.split3.surtitre }}
                </h2>
                <h3 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.split3.titre"></span>
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.split3.texte"></span>
                </p>
                <a
                    :href="content.split3.boutonUrl[$route.meta.lang]"
                    class="cta"
                    data-inview="fadeInUp"
                    data-delay="500"
                >
                    <p class="regular-text">{{ content.split3.bouton.customText }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                </a>
            </div>
            <!-- </div> -->
            <div class="img" data-inview="revealRight" data-delay="200">
                <img :src="content.split3.image.url" :alt="content.split3.image.titre" />
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'
// import Flickity from 'flickity'

export default {
    data() {
        return {
            lightboxelement: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped></style>
